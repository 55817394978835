<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-row class="justify-content-center" v-if="myUserData.status">
      <v-col cols="12">
        <div class="pa-5">
          <div class="d-flex align-items-end mb-6">
            <div
              class="img-box pointer"
              @click="$refs.profileImage.$refs.input.click()"
            >
              <img
                height="150"
                width="150"
                class="img"
                :src="
                  myUserData.picture
                    ? myUserData.picture
                    : 'https://www.sibberhuuske.nl/wp-content/uploads/2016/10/default-avatar-300x300.png'
                "
              />
              <v-icon x-large class="img-icon white--text">mdi-camera</v-icon>
              <div class="img-loading" v-if="profileLoading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="40"
                ></v-progress-circular>
                <h6 class="mt-4 grey--text text--darken-4">
                  در حال بارگذاری...
                </h6>
              </div>
            </div>
            <v-btn
              class="purple-btn pa-5 ms-4"
              @click="$refs.profileImage.$refs.input.click()"
            >
              <v-icon class="me-2">mdi-camera-outline</v-icon>
              بارگذاری تصویر
            </v-btn>
            <v-file-input
              v-show="false"
              @change="uploadProfile(imageFile)"
              v-model="imageFile"
              ref="profileImage"
            ></v-file-input>
          </div>
          <v-row>
            <v-col>
              <v-select
                label="وضعیت"
                outlined
                dense
                :items="['فعال', 'غیرفعال']"
                v-model="myUserData.status"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="job == 'reception'">
            <v-col>
              <v-text-field
                label="نام کاربری"
                outlined
                dense
                disabled
                v-model="myUserData.username"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="نقش"
                outlined
                dense
                disabled
                v-model="myUserData.role"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                label="نام و نام‌خانوادگی"
                outlined
                dense
                v-model="myUserData.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                label="نام پدر"
                outlined
                dense
                v-model="myUserData.fatherName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="myUserData.maritalStatus"
                :items="maritalStatuses"
                item-text="text"
                item-value="value"
                label="وضعیت تاهل"
                outlined
                dense
              ></v-select
            ></v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="myUserData.maritalStatus == 'married'"
                v-model="myUserData.numberOfChildren"
                label="تعداد فرزند"
                type="number"
                outlined
                dense
              ></v-text-field
            ></v-col>
          </v-row>
          <div v-if="job == 'doctor'" class="text-right mb-2 common-text">
            لطفا تخصص پزشک را از بین گزینه‌ها یا بصورت دستی وارد کنید
          </div>
          <v-row>
            <v-col
              v-if="job == 'doctor'"
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <v-combobox
                v-model="myUserData.expertise"
                :items="expertSuggests"
                label="تخصص"
                ref="expertise"
                outlined
                dense
              ></v-combobox>
            </v-col>
            <v-col
              v-if="job == 'doctor' || job == 'nurse'"
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <v-text-field
                outlined
                dense
                label="شماره نظام"
                disabled
                v-model="myUserData.docCode"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="myUserData.natCode"
                label="کدملی"
                type="number"
                outlined
                :rules="[isID]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-select
                label="جنسیت"
                outlined
                dense
                v-model="myUserData.gender"
                :items="genders"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="job == 'nurse'">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-select
                label="مدرک تحصیلی"
                v-model="myUserData.education"
                :items="education"
                outlined
                dense
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                label="مدرک"
                outlined
                dense
                v-if="myUserData.education == 'سایر'"
                v-model="myUserData.otherEducation"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-text-field
                label="تحصیلات"
                v-model="myUserData.education"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="شماره موبایل"
                outlined
                dense
                v-model="myUserData.mobile"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="تلفن ثابت"
                outlined
                dense
                v-model="myUserData.tel"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-show="false">{{ reactivity }}</span>
              <span id="date">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="myUserData.BDay"
                  :editable="true"
                  class="date-input"
                  label="تاریخ تولد"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="myUserData.BDay"
                element="date"
                color="#00a7b7"
                @change="reactivity = !reactivity"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                id="email"
                label="ایمیل"
                v-model="myUserData.email"
                outlined
                dense
                :rules="[
                  validateEmail(myUserData.email)
                    ? true
                    : 'ایمیل وارد شده صحیح نمی‌باشد',
                ]"
                type="email"
              ></v-text-field>
            </v-col>
            <!-- inja -->
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <vuetify-money
                v-model="myUserData.salaryPerHour"
                :valueWhenIsEmpty="whenIsEmpty"
                :options="vMoneyOptions"
                label="حقوق ساعتی (ریال)"
                type="number"
                outlined
                dense
            /></v-col>
            <v-col cols="12" md="6" v-if="job == 'doctor'">
              <v-text-field
                outlined
                dense
                label="مدت زمان ویزیت به دقیقه"
                v-model="myUserData.visitDuration"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="myUserData.state"
                outlined
                dense
                label="استان"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="myUserData.city"
                outlined
                dense
                label="شهر"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="myUserData.address"
                label="آدرس دقیق"
                outlined
                dense
                class="mt-2"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="myUserData.canSendOut" color="primary">
                <template v-slot:label>
                  <span class="mt-2"
                    >امکان امضای نامه برای این کاربر وجود دارد.</span
                  >
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-btn class="primary-btn" @click="editPerson()">ویرایش </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

function checkCodeMeli(code) {
  //NOTE employee's checking validation of national code
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}

export default {
  props: ["userData", "job"],
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      myUserData: {},
      reactivity: false,
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      education: [
        { value: "کمک بهیار", text: "کمک بهیار" },
        { value: "بهیار", text: "بهیار" },
        { value: "تکنسین اتاق عمل", text: "تکنسین اتاق عمل" },
        { value: "فوریت های پزشکی", text: "فوریت های پزشکی" },
        { value: "کارشناس مامائی", text: "کارشناس مامائی" },
        { value: "کارشناس پرستاری", text: "کارشناس پرستاری" },
        { value: "کارشناس اتاق عمل", text: "کارشناس اتاق عمل" },
        { value: "کارشناس فیزیوتراپی", text: "کارشناس فیزیوتراپی" },
        { value: "کارشناس بینایی سنجی", text: "کارشناس بینایی سنجی" },
        { value: "کارشناس شنوایی سنجی", text: "کارشناس شنوایی سنجی" },
        { value: "کاردان علوم آزمایشگاهی", text: "کاردان علوم آزمایشگاهی" },
        { value: "کارشناس علوم آزمایشگاهی", text: "کارشناس علوم آزمایشگاهی" },
        { value: "تکنسین رادیولوژی", text: "تکنسین رادیولوژی" },
        { value: "کارشناش رادیولوژی", text: "کارشناش رادیولوژی " },
        { value: "سایر", text: "سایر" },
      ],
      expertSuggests: ["عمومی", "دندانپزشک"],
      Busy: false,
      imageFile: [],
      myImageFile: new Object({
        uploadPercentage: 0,
      }),
      profileLoading: false,
      maritalStatuses: [
        { value: "married", text: "متاهل" },
        { value: "single", text: "مجرد" },
      ],
    };
  },

  methods: {
    validateEmail(email) {
      //NOTE checking validation of email
      if (email) {
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      } else return true;
    },
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    editPerson() {
      if (this.job == "doctor") {
        this.$refs["expertise"].blur();
      }
      setTimeout(() => {
        //NOTE adding new doctor to server
        this.vLoading = true;
        this.$http
          .post(
            this.baseUrl + "/clinic/hr/employee/edit",
            {
              userId: this.$route.params.id,
              status: this.myUserData.status,
              name: this.myUserData.name,
              mobile: this.myUserData.mobile,
              tel: this.myUserData.tel,
              expertise: this.myUserData.expertise,
              docCode: this.myUserData.docCode,
              email: this.myUserData.email,
              address: this.myUserData.address,
              gender: this.myUserData.gender,
              password:
                this.myUserData.password == this.myUserData.repeatPassword
                  ? this.myUserData.password
                  : "",
              education: this.myUserData.education,
              natCode: this.myUserData.natCode,
              fatherName: this.myUserData.fatherName,
              city: this.myUserData.city,
              state: this.myUserData.state,
              BDay: this.myUserData.BDay,
              visitDuration:
                this.job == "doctor" ? this.myUserData.visitDuration : "",
              otherEducation: this.myUserData.otherEducation,
              canSendOut: this.myUserData.canSendOut,
              maritalStatus: this.myUserData.maritalStatus,
              numberOfChildren:
                this.myUserData.maritalStatus == "married"
                  ? this.myUserData.numberOfChildren
                  : undefined,
              salaryPerHour: this.myUserData.salaryPerHour,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.vLoading = false;
              this.toast(res.data, "success");
              this.$emit("getInfo");
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      }, 500);
    },
    uploadProfile(file) {
      if (!file) {
        return;
      }
      let sizeMB = file.size / 1024 / 1024;

      if (sizeMB > 5) {
        this.toast(
          "حجم فایل " + file.name + " نباید از 5 مگابایت بیشتر باشد",
          "error"
        );
        return;
      } else if (file.type != "image/jpeg" && file.type != "image/png") {
        this.toast("فرمت فایل مورد نظر مجاز نمی‌باشد.", "error");
        return;
      } else {
        // NOTE (m-myUpload) to upload files
        this.myImageFile.showProgress = true;
        this.myImageFile.variant = "info";
        this.myImageFile.uploadPercentage = 0;
        this.myImageFile.isUploaded = false;
        this.myImageFile.uploadButDisable = true;
        var formData = new FormData();
        formData.append(file.name, file);
        this.profileLoading = true;
        this.$http
          .post(
            this.baseUrl +
              "/clinic/hr/employee/uploadPicture?userId=" +
              this.$route.params.id,
            formData,
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
              onUploadProgress: ((progressEvent) => {
                this.myImageFile.uploadPercentage =
                  Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  ) - 1;
              }).bind(this),
            }
          )
          .then((d) => {
            if (d.status == 201) {
              this.myImageFile.isUploaded = true;
              this.myImageFile.uploadPercentage = 100;
              this.myImageFile.striped = false;
              this.myImageFile.variant = "success";
              this.myImageFile.uploadButDisable = false;
              this.myUserData.picture = d.data.url;
              this.profileLoading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast(err.response.data, "error");
            this.profileLoading = false;
          });
      }
    },
  },

  mounted() {
    this.myUserData = JSON.parse(JSON.stringify(this.userData));
  },
};
</script>
